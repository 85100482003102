<template>
    <div>
        <b-card no-body class="mb-2 p-1">
            <div class="row">
                <b-col cols="3">
                    <router-link
                        :to="{ name: 'ShiftClosingWizard' }"
                        class="btn btn-success"
                    >
                        <b-icon-plus scale="1.2"/>
                    </router-link>
                </b-col>
                <b-col cols="9" class="text-right">
                    <b-button variant="primary" @click="applyFilters" class="mr-1">
                        <b-icon-arrow-repeat font-scale="1.2"/>
                    </b-button>
                    <b-button v-b-toggle.filters variant="light">
                        <b-icon-filter/>
                        Фильтры
                    </b-button>
                    <small v-if="filtersExists">
                        <b-link @click.prevent="resetFilters">(Сбросить)</b-link>
                    </small>
                </b-col>
            </div>
            <b-collapse id="filters">
                <b-card-body>
                    <div class="row">
                        <div class="col-md-3 no-border">
                            <b-form-group description="Дата от">
                                <div class="row m-0">
                                    <datetime type="date" class="form-control no-border col-10" v-model="filters.dateFrom"></datetime>
                                    <div class="col-2 p-0">
                                        <button class="btn btn-xs btn-ghost-secondary" @click="filters.dateFrom = null"><i class="fas fa-times"></i></button>
                                    </div>
                                </div>
                            </b-form-group>
                        </div>
                        <div class="col-md-3">
                            <b-form-group description="Дата до">
                                <div class="row m-0">
                                    <datetime type="date" class="form-control no-border col-10" v-model="filters.dateTo"></datetime>
                                    <div class="col-2 p-0">
                                        <button class="btn btn-xs btn-ghost-secondary" @click="filters.dateTo = null"><i class="fas fa-times"></i></button>
                                    </div>
                                </div>
                            </b-form-group>
                        </div>
                        <div class="col-md-3">
                            <b-form-group description="Цех">
                                <storage-picker selectable-for-task-list :allowed-roles="storageAllowedRoles" :nullable="true" v-model="filters.storage"/>
                            </b-form-group>
                        </div>
                        <div class="col-md-3">
                            <b-form-group description="Смена">
                                <shift-input v-model="filters.productionShift"></shift-input>
                            </b-form-group>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>
        <b-table
            responsive
            :items="data"
            :fields="fields"
            head-variant="dark"
            :sort-by.sync="filters.orderBy.by"
            :sort-desc.sync="filters.orderBy.desc"
            no-local-sorting
            no-sort-reset
            @row-dblclicked="toForm"
        >
            <template #cell(actions)="data">
                <div class="text-right">
                    <button class="btn btn-primary btn-sm"
                            @click="toForm(data.item)"
                            title="Редактировать"><i class="fas fa-edit"></i></button>
                </div>
            </template>
        </b-table>
        <b-row>
            <b-col cols="6">
                <span>
                    Показано с {{ meta.current_page * meta.per_page - meta.per_page + 1 }}
                    по {{ meta.current_page * meta.per_page - meta.per_page + data.length }}
                    из {{ meta.total_rows }} записей
                </span>
            </b-col>
            <b-col cols="6" class="d-flex justify-content-end">
                <b-pagination
                    v-model="meta.current_page"
                    :total-rows="meta.total_rows"
                    :per-page="meta.per_page"
                    @input="applyFilters"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import moment from 'moment'
    import forEach from 'lodash/forEach';
    import { Datetime } from 'vue-datetime';
    import lsfMixin from '@utils/localStorageFilterMixin';
    import StoragePicker from '@components/_common/StoragePicker';
    import ShiftInput from '@components/_common/ShiftInput';
    import {SHIFT_CLOSING_ACT_ENDPOINT} from "@utils/endpoints";
    import {ROLE_GROUP_SENIOR, ROLE_STORAGE_SENIOR} from "@utils/Roles";

    const FILTER_PATTERN = {
        dateFrom: null,
        dateTo: null,
        storage: null,
        productionShift: null,
        page: 1,
        orderBy: {
            by: 'id',
            desc: true,
        }
    };

    export default {
        components: {
            datetime: Datetime,
            StoragePicker,
            ShiftInput,
        },
        mixins: [lsfMixin],
        name: "ShiftClosingActList",
        computed: {
            params() {
                let params = {
                    page: this.meta.current_page,
                    with: this.with,
                };

                forEach(this.getFilters, (filter, key) => {
                    if (filter) {

                        switch (key) {
                            case 'storage':
                                params['filters[storage_id]'] = filter.id;
                                break;
                            case 'productionShift':
                                params['filters[production_shift_id]'] = filter.id;
                                break;
                            case 'orderBy':
                                if (!!filter.by) {
                                    params['sort'] = filter.by;
                                    params['sortDirection'] = filter.desc ? 'desc' : 'asc';
                                }
                                break;
                            default:
                                params['filters['+key+']'] = filter;
                                break;
                        }

                    }
                });

                return params;
            },
            storageAllowedRoles() {
                return [ROLE_GROUP_SENIOR, ROLE_STORAGE_SENIOR];
            },
        },
        watch: {
            'filters': {
                handler: function () {
                    this.watchFilters(this.filters);
                },
                deep: true,
            },
            orderBy: {
                deep: true,
                handler() {
                    this.applyFilters();
                }
            },
        },
        data() {
            return {
                loading: false,
                cancelLoading: {},
                filters: {
                    dateFrom: null,
                    dateTo: null,
                    storage: null,
                    productionShift: null,
                    orderBy: {
                        by: 'id',
                        desc: true,
                    }
                },
                data: [],
                meta: {
                    total_rows: 0,
                    current_page: 1,
                    per_page: 25,
                },
                with: [
                    'storage',
                    'productionShift',
                ],
                fields: [
                    {
                        key: 'id',
                        label: '№',
                        sortable: true,
                    },
                    {
                        key: 'blocked',
                        label: 'Закрыта',
                        formatter: blocked => !!blocked ? 'Да' : 'Нет',
                        sortable: false,
                    },
                    {
                        key: 'start_at',
                        label: 'Начало смены',
                        formatter: start_at => moment(start_at).format('DD.MM.YY HH:mm'),
                        sortable: true,
                    },
                    {
                        key: 'end_at',
                        label: 'Конец смены',
                        formatter: end_at => moment(end_at).format('DD.MM.YY HH:mm'),
                        sortable: true,
                    },
                    {
                        key: 'storage',
                        label: 'Цех',
                        formatter: storage => storage.name,
                        sortable: false,
                    },
                    {
                        key: 'productionShift',
                        label: 'Смена',
                        formatter: productionShift => productionShift ? productionShift.name : '',
                        sortable: false,
                    },
                    {
                        key: 'actions',
                        label: ''
                    }
                ],
            }
        },
        methods: {
            moment,
            applyFilters() {
                this.loading = true;
                this.$http.get(SHIFT_CLOSING_ACT_ENDPOINT, {
                    params: this.params
                }).then(response => {
                    this.data = response.data.data.data;
                    this.meta = {
                        total_rows: response.data.data.total,
                        current_page: response.data.data.current_page,
                        per_page: response.data.data.per_page,
                    }
                }).catch(error => {
                    if (error.data.message) {
                        this.$toast.error(error.data.message);
                    } else {
                        this.$toast.error('Ошибка!');
                    }
                }).finally(() => {
                    this.loading = false;
                })
            },
            toForm(item) {
                if (item.step === null) {
                    this.$router.push({name: 'ShiftClosingActUpdate', params: {id: item.id}});
                } else {
                    this.$router.push({name: 'ShiftClosingWizard', params: {id: item.id}});
                }
            },
            toggleCancel(item) {

                let value = !item.canceled;

                this.$set(this.cancelLoading, item.id,  true);

                this.$http.put(SHIFT_CLOSING_ACT_ENDPOINT + `/${item.id}/cancel`, {
                    id: item.id,
                    canceled: value ? 1 : 0,
                },{
                    params: {
                        without_loading: true
                    }
                }).then( response => {
                    item.canceled = value;
                }).catch( error => {
                    if (error.data.message) {
                        this.$toast.error(error.data.message);
                    } else {
                        this.$toast.error('Ошибка!');
                    }
                }).finally(() => {
                    this.$delete(this.cancelLoading, item.id);
                });
            }
        },
        created() {
            this.initFilters(this, FILTER_PATTERN);
        }
    }
</script>

<style>
    .no-border input {
        border: none;
    }
</style>
